<template>
  <section class="mx-md-9 mx-sm-6">
    <!-- title -->
    <div class="my-6 d-flex flex-column">
      <span
        class="text-h5 secondary--text"
        v-text="'Administración de asuetos'"
      />
    </div>

    <!-- action create -->
    <div
      class="mt-6 d-flex justify-center flex-column justify-sm-start flex-sm-row"
    >
      <!-- boton que redirecciona a la vista de crear proceso de compra con reserva -->
      <v-btn
        class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-0"
        color="secondary"
        @click="show_dialog_create_var = true"
      >
        Agregar nuevo asueto
      </v-btn>
    </div>

    <!-- filtros -->
    <v-row class="mt-6">
      <!-- filtro por codigo o nombre -->
      <v-col cols="12" sm="6">
        <v-text-field
          clearable
          dense
          hide-details
          label="Descripción"
          maxlength="150"
          outlined
          v-model="filter_descp_var"
        />
      </v-col>

      <!-- filtros por fecha -->
      <v-col cols="12" md="5" sm="8">
        <div class="d-flex flex-column flex-sm-row align-sm-center">
          <v-menu
            :close-on-content-click="false"
            max-width="290px"
            min-width="auto"
            offset-y
            transition="scale-transition"
            v-model="filter_fecha_desde_var.menu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="FormatDateFtn(filter_fecha_desde_var.value)"
                @click:clear="filter_fecha_desde_var.value = null"
                append-icon="mdi-calendar"
                class="mb-6 mb-sm-0 pr-sm-3"
                clearable
                dense
                hide-details
                label="Desde"
                outlined
                placeholder="Seleccione una fecha"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              :max="filter_fecha_hasta_var.value"
              @input="filter_fecha_desde_var.menu = false"
              no-title
              scrollable
              v-model="filter_fecha_desde_var.value"
            />
          </v-menu>
          <v-menu
            :close-on-content-click="false"
            max-width="290px"
            min-width="auto"
            offset-y
            transition="scale-transition"
            v-model="filter_fecha_hasta_var.menu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="filter_fecha_desde_var.value == null"
                :value="FormatDateFtn(filter_fecha_hasta_var.value)"
                append-icon="mdi-calendar"
                class="mb-6 mb-sm-0 px-sm-3"
                clearable
                dense
                hide-details
                label="Hasta"
                outlined
                placeholder="Seleccione una fecha"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              :min="filter_fecha_desde_var.value"
              @input="filter_fecha_hasta_var.menu = false"
              no-title
              scrollable
              v-model="filter_fecha_hasta_var.value"
            />
          </v-menu>

          <!-- boton buscar -->
          <v-btn
            color="secondary"
            class="ml-sm-3 text-no-style"
            dark
            @click="FilterListAsuetoFtn"
          >
            <v-icon> mdi-magnify </v-icon>
          </v-btn>
          <v-btn
            color="secondary"
            class="ml-sm-3 mt-6 mt-sm-0 text-no-style"
            outlined
            @click="CleanFiltersFtn"
          >
            <v-icon color="secondary"> mdi-broom </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- table -->
    <data-table-component
      :headers="HEADERS_CONST"
      :items="list_asueto_var"
      mobile_breakpoint="1300"
      table_title="Listado de días de asueto"
      :total_registros="list_asueto_pag.total_rows"
      @paginar="PaginateFtn"
      v-models:pagina="list_asueto_pag.page"
      v-models:select="list_asueto_pag.per_page"
    >
      <!-- slot de fecha de inicio de proceso -->
      <template v-slot:[`item.fecha`]="{ item }">
        {{ FormatDateFtn(item.fecha) }}
      </template>

      <!-- slot de acciones -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="
                (selected_item_var = item), (show_dialog_delete_var = true)
              "
            >
              <v-icon color="secondary">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span> Eliminar </span>
        </v-tooltip>
      </template>
    </data-table-component>

    <confirmation-dialog-component
      :show="show_dialog_delete_var"
      title="¿Deseas eliminar el asueto?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="(show_dialog_delete_var = false), (selected_item_var = null)"
      @confirm="DeleteAsuetoFtn"
    />

    <v-dialog v-model="show_dialog_create_var" persistent max-width="800">
      <v-card>
        <div class="headerIcon d-flex justify-end">
          <v-icon class="secondary--text" @click="CloseModalAddFtn">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <v-row class="mx-sm-6">
            <v-col cols="12">
              <span class="text-h6 secondary--text">
                Registro de nuevo asueto
              </span>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="date_var.menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="FormatDateFtn(date_var.value)"
                    append-icon="mdi-calendar"
                    clearable
                    hide-details
                    label="Fecha *"
                    outlined
                    placeholder="Seleccione una fecha"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @blur="$v.date_var.value.$touch()"
                    :error-messages="dateErrorsCmp"
                  />
                </template>
                <v-date-picker
                  v-model="date_var.value"
                  :min="moment().format('YYYY-MM-DD')"
                  @input="date_var.menu = false"
                  no-title
                  scrollable
                />
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Descripción *"
                v-model="descripcion_var"
                outlined
                rows="4"
                auto-grow
                clearable
                maxlength="250"
                counter="250"
                @blur="$v.descripcion_var.$touch()"
                :error-messages="descripcionErrorsCmp"
              />
            </v-col>
          </v-row>
          <!-- acciones -->
          <v-row cols="12" class="d-flex flex-column flex-sm-row mx-sm-4 mb-6">
            <v-btn
              class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
              min-width="30%"
              color="secondary"
              outlined
              @click="CloseModalAddFtn"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
              min-width="30%"
              color="secondary"
              @click="CreateAsuetoFtn"
            >
              Registrar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
// importacion de componentes globales
import DataTableComponent from "../components/DataTableComponent.vue";
import ConfirmationDialogComponent from "../components/ConfirmationDialogComponent.vue";

// importacion de librerias
import { required } from "vuelidate/lib/validators";

export default {
  name: "AdminAsuetoView",

  components: {
    DataTableComponent,
    ConfirmationDialogComponent,
  },

  validations: {
    descripcion_var: {
      required,
    },
    date_var: {
      value: {
        required,
      },
    },
  },

  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        text: "Fecha",
        value: "fecha",
        align: "center",
      },
      {
        text: "Descripción",
        value: "descripcion",
        width: "70%",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
      },
    ],
    FILTERS_SNAPSHOT_CONST: {},

    // variables
    filter_descp_var: null,
    filter_fecha_desde_var: {
      menu: false,
      value: null,
    },
    filter_fecha_hasta_var: {
      menu: false,
      value: null,
    },

    list_asueto_var: [],
    list_asueto_pag: {
      page: 1,
      per_page: 10,
      total_rows: 0,
    },

    selected_item_var: null,
    show_dialog_delete_var: false,
    show_dialog_create_var: false,

    date_var: {
      menu: false,
      value: null,
    },
    descripcion_var: null,
  }),

  computed: {
    dateErrorsCmp() {
      const errors = [];
      if (!this.$v.date_var.value.$dirty) return errors;
      !this.$v.date_var.value.required && errors.push("Fecha requerida");
      return errors;
    },

    descripcionErrorsCmp() {
      const errors = [];
      if (!this.$v.descripcion_var.$dirty) return errors;
      !this.$v.descripcion_var.required && errors.push("Descripción requerida");
      return errors;
    },
  },

  methods: {
    // <-- ----------------- FUNCIONES REGULARES ----------------- -->

    /**
     * @description Funcion para formatear fecha
     * @param {Date} date_param
     * @returns {String} Fecha formateada
     */
    FormatDateFtn(date_param) {
      if (date_param != null) {
        return this.moment(date_param).format("DD/MM/YYYY");
      } else {
        return "";
      }
    },

    /**
     * @description Funcion para limpiar los filtros
     */
    CleanFiltersFtn() {
      this.filter_descp_var = null;
      this.filter_fecha_desde_var.value = null;
      this.filter_fecha_hasta_var.value = null;

      this.FILTERS_SNAPSHOT_CONST = {};

      this.FetchListAsuetoFtn({ page: 1, per_page: 10 });
    },

    /**
     *
     * @description Funcion para paginar la lista de procesos
     * @param { Number } cantidad_por_pagina
     * @param { Number } pagina
     */
    PaginateFtn({ cantidad_por_pagina, pagina }) {
      this.FetchListAsuetoFtn({
        per_page: cantidad_por_pagina,
        page: pagina,
      });
    },

    /**
     * @description Funcion filtrar la lista de procesos, validar los campos y preparar payload
     */
    FilterListAsuetoFtn() {
      if (
        this.filter_fecha_desde_var.value != null &&
        this.filter_fecha_hasta_var.value == null
      ) {
        this.temporalAlert({
          message: "Debe seleccionar una fecha hasta",
          show: true,
          type: "warning",
        });
        return;
      }

      const filters_scp_var = {
        descripcion: this.filter_descp_var || undefined,
        fecha_desde: this.filter_fecha_desde_var.value || undefined,
        fecha_hasta: this.filter_fecha_hasta_var.value || undefined,
      };

      const isAllUndefined = Object.values(filters_scp_var).every(
        (x) => x === undefined
      );

      if (isAllUndefined) {
        this.temporalAlert({
          message: "Debe ingresar al menos un filtro",
          show: true,
          type: "warning",
        });
        return;
      }

      this.FILTERS_SNAPSHOT_CONST = filters_scp_var;

      this.FetchListAsuetoFtn({ page: 1, per_page: 10 });
    },

    /**
     *
     * @description: funcion para cerrar el modal de agregar fondo
     */
    CloseModalAddFtn() {
      this.show_dialog_create_var = false;
      this.date_var.value = null;
      this.descripcion_var = null;

      this.$v.$reset();
    },

    // <-- ----------------- FUNCIONES ASINCRONAS ---------------- -->

    /**
     * @description Funcion para obtener el listado de procesos (API)
     * @async
     */
    async FetchListAsuetoFtn(pagination_param = {}) {
      if (typeof pagination_param !== "object") {
        console.error("El argumento debe ser un objeto");
        return;
      }


      const { data, status, headers } =
        await this.services.Asuetos.getListAsuetos({
          ...this.FILTERS_SNAPSHOT_CONST,
          ...pagination_param,
        });

      if (status == 200) {
        this.list_asueto_var = data;
        this.list_asueto_pag.total_rows = Number(headers.total_rows);
        this.list_asueto_pag.page = Number(headers.page);
        this.list_asueto_pag.per_page = Number(headers.per_page);
      }

    },

    async DeleteAsuetoFtn() {

      const { status } = await this.services.Asuetos.deleteAsueto(
        this.selected_item_var.id
      );


      if (status == 200) {
        this.temporalAlert({
          message: "Asueto eliminado correctamente",
          show: true,
          type: "success",
        });
      }

      this.show_dialog_delete_var = false;
      this.selected_item_var = null;
      this.FetchListAsuetoFtn({ page: 1, per_page: 10 });
    },

    /**
     *
     * @description: funcion para crear un registro de fondo
     */
    async CreateAsuetoFtn() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.temporalAlert({
          message: "Por favor, complete los campos requeridos",
          show: true,
          type: "warning",
        });
        return false;
      }


      const { status } = await this.services.Asuetos.postAsueto({
        fecha: this.date_var.value,
        descripcion: this.descripcion_var,
      });


      if (status === 201) {
        this.temporalAlert({
          message: "Registro del asueto creado correctamente",
          show: true,
          type: "success",
        });
        this.FetchListAsuetoFtn({ page: 1, per_page: 10 });
      }
      this.CloseModalAddFtn();
    },
  },

  watch: {
    "filter_fecha_desde_var.value"(val) {
      if (val == null) {
        this.filter_fecha_hasta_var.value = null;
      }
    },
  },

  created() {
    this.FetchListAsuetoFtn();
  },
};
</script>

<style>
</style>